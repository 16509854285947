import { z } from "zod";

import { SuccessResponseValidator } from "../../shared/http/dto/BaseResponse.dto";
import { request } from "../../shared/http/HTTP";
import { IAddToBasketEvent } from "../../shared/utils/analytics/events/addToBasket";
import { CartAddMetadataValidator, CartValidator, ICart } from "./dto/Cart.dto";
import { ShareCartValidator } from "./dto/ShareCart.dto";

export const fetchCart = async () => {
  const resp = await request("/api/v3/cart/");
  return SuccessResponseValidator(CartValidator).parse(await resp.json()).data;
};

export const fetchCartItemMetadata = async (aonumber: string) => {
  const resp = await request(`/api/v3/cart/metadata/${aonumber}`);
  return SuccessResponseValidator(CartAddMetadataValidator).parse(await resp.json()).data;
};

export interface ICartItemUpdate {
  productId: string;
  quantity: number;
  source: IAddToBasketEvent["source"];
  note?: string;
}

export const addToCart: (updates: ICartItemUpdate[]) => Promise<ICart> = async (updates) => {
  /**
   * Naively adds a product to the cart.
   */

  const resp = await request("/api/v3/cart/item", {
    method: "POST",
    body: JSON.stringify({ updates }),
  });
  return SuccessResponseValidator(CartValidator).parse(await resp.json()).data;
};

export const updateCartItem: (updates: {
  lineId: string;
  source: IAddToBasketEvent["source"];
  quantity: number;
}) => Promise<ICart> = async ({ lineId, quantity, source }) => {
  const resp = await request(`/api/v3/cart/item/${lineId}`, {
    method: "PATCH",
    body: JSON.stringify({ quantity, source }),
  });
  return SuccessResponseValidator(CartValidator).parse(await resp.json()).data;
};

export const removeCartItem: (updates: {
  lineId: string;
  source: IAddToBasketEvent["source"];
}) => Promise<ICart> = async ({ lineId, source }) => {
  const resp = await request(`/api/v3/cart/item/${lineId}`, {
    method: "DELETE",
    body: JSON.stringify({ source }),
  });
  return SuccessResponseValidator(CartValidator).parse(await resp.json()).data;
};

export const updateRebateCode: (update: { rebateCode: string | undefined }) => Promise<ICart> = async ({
  rebateCode,
}) => {
  const resp = await request(`/api/v3/cart/rebate-code`, {
    method: "POST",
    body: JSON.stringify({ rebate_code: rebateCode ?? null }),
  });
  return SuccessResponseValidator(CartValidator).parse(await resp.json()).data;
};

export const emptyCart = async () => {
  const resp = await request("/api/v3/cart/", {
    method: "DELETE",
  });
  return SuccessResponseValidator(CartValidator).parse(await resp.json()).data;
};

export const saveCart = async () => {
  const resp = await request("/api/v3/cart/:save", { method: "POST" });
  return SuccessResponseValidator(z.unknown()).parse(await resp.json()).data;
};

export const deleteSaveCart = async (id: string) => {
  const resp = await request(`/api/v3/cart/saved/${id}`, { method: "DELETE" });
  return SuccessResponseValidator(z.unknown()).parse(await resp.json()).data;
};

export const shareCart = async (options?: { type?: "share" | "public" }) => {
  const type = options?.type ?? "share";
  const resp = await request(`/api/v3/cart/:share?type=${type}`, { method: "POST" });
  return SuccessResponseValidator(ShareCartValidator).parse(await resp.json()).data;
};
