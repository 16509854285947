import React, { FunctionComponent, useState } from "react";

import { configuration } from "./Configuration";
import { ConfigurationProvider } from "./ConfigurationContext";

export const BrowserConfigurationManager: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const [config] = useState(() => {
    return configuration();
  });
  return <ConfigurationProvider value={{ configuration: config }}>{children}</ConfigurationProvider>;
};
