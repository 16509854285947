import clsx from "clsx";
import AlertError from "icons/error.svg";
import AlertCheck from "icons/success.svg";
import AlertWarning from "icons/warning.svg";
import React, { FunctionComponent } from "react";

import { ModalSize, ModalType } from "../Modal";
import styles from "./ModalHeader.style.scss";

interface IModalHeaderProps {
  title: string;
  className?: string;
  subtitle?: string;
  size?: ModalSize;
  type?: ModalType;
}

const ICONS: Record<ModalType, JSX.Element> = {
  info: <AlertError />,
  warning: <AlertWarning />,
  error: <AlertError />,
  default: <AlertError />,
  success: <AlertCheck />,
};

const ModalHeader: FunctionComponent<React.PropsWithChildren<IModalHeaderProps>> = ({
  children,
  title,
  className,
  subtitle,
  size,
  type,
  ...props
}) => {
  return title ? (
    <div className={clsx(className, styles[`size-${size ?? "default"}`], type && styles[type])} {...props}>
      <div className={styles.headerContainer}>
        {type && <div className={clsx(styles.icon)}>{ICONS[type]}</div>}
        {size === "fullscreen" ? (
          <h2 className={styles.fullscreenHeader}>{title}</h2>
        ) : (
          <div className={styles.header}>{title}</div>
        )}
      </div>
      {subtitle ? <h6 className={styles.subtitle}>{subtitle}</h6> : undefined}
      {children}
    </div>
  ) : (
    ""
  );
};

export const _ModalHeader = ModalHeader;
