import { formData, HTTPError, request } from "../../shared/http/HTTP";

/* Subscribes a given user with a name and email to the current mailing list,
   calls the callback after the backend returns. */
export function subscribe(
  name: string,
  email: string,
  source: string,
  phone: string,
  method: string,
  custom_property_name?: string,
  custom_property_value?: string,
  callback?: (data: Record<string, string>) => void,
) {
  const bodyData: Record<string, string> = {
    email,
    method,
    name,
    source,
    phone,
    custom_property_name: custom_property_name || "",
    custom_property_value: custom_property_value || "",
  };

  request(
    "/nyhedsbrev/rpc/",
    {
      method: "post",
      body: formData(bodyData),
    },
    { defaultHeaders: false },
  )
    .then(async (resp) => {
      callback?.(await resp.json());
    })
    .catch(async (error) => {
      if (error instanceof HTTPError) {
        const resp = await error.response.json();
        alert(resp.error);
      }
    });
}
