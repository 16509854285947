import { z } from "zod";

const BaseHeaderElementValidator = z.object({
  id: z.number(),
  name: z.string(),
  link: z.string(),

  span_columns: z.number().nullish(),
});

type HeaderElement = z.infer<typeof BaseHeaderElementValidator> & {
  children: HeaderElement[];
};

export const HeaderEntryValidator: z.ZodType<HeaderElement> = BaseHeaderElementValidator.extend({
  children: z.lazy(() => HeaderEntryValidator.array()),
});

export const HeaderValidator = z.object({
  entries: HeaderEntryValidator.array(),
});

export type IHeaderEntry = z.infer<typeof HeaderEntryValidator>;
export type IHeader = z.infer<typeof HeaderValidator>;
