import React, { FunctionComponent, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useMemoOne } from "use-memo-one";

import { useLocalStorage } from "../../hooks/useLocalStorage";

const AdminMenu: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const existing = useMemoOne(() => document.querySelector("#AdminTools .react-contents"), []);
  const [pinned, setPinned] = useLocalStorage("admintool-pinned", false);

  if (existing) {
    return createPortal(children, existing);
  }
  if (React.Children.count(children) > 0) {
    return createPortal(
      <div id="AdminTools" className={pinned ? "pinned" : undefined}>
        <div className={"toggle-button"}>ADMINISTRATION</div>
        <h1>
          Administration
          <div className={`js-admintools-pin`} onClick={() => setPinned(!pinned)}>
            ☨
          </div>
        </h1>
        <div className="contents">{children}</div>
      </div>,
      document.body,
    );
  }
  return null;
};

export default AdminMenu;
