import { z } from "zod";

export const PageViewValidator = z.object({
  url: z.string(),
  page_type: z.string().optional(),
  page_name: z.string().optional(),
  page_id: z.coerce.string().optional(),
  user_agent: z.string(),
  screen_width: z.number(),
  screen_height: z.number(),
  referrer: z.string().optional(),
  session_time: z.number(),
});

export type IPageViewEvent = z.infer<typeof PageViewValidator>;
