declare global {
  interface Window {
    CookieConsent?: {
      renew: () => void;
    };
    CookieInformation?: {
      getConsentGivenFor: (type: string) => boolean;
      changeCategoryConsentDecision: (type: string, value: boolean) => boolean;
      submitConsent: () => void;
    };
  }
}

export type ConsentType = "statistic" | "marketing" | "necessary" | "functional";

export function openConsentModal() {
  window.CookieConsent?.renew();
}

export function getConsent(consent: ConsentType) {
  if (consent === "necessary") {
    return true;
  }

  return window.CookieInformation?.getConsentGivenFor(`cookie_cat_${consent}`) ?? false;
}

export function getConsents() {
  return {
    statistic: getConsent("statistic"),
    marketing: getConsent("marketing"),
    functional: getConsent("functional"),
    necessary: getConsent("necessary"),
  };
}

export function setConsent(consent: ConsentType | ConsentType[], value: boolean) {
  const consents = Array.isArray(consent) ? consent : [consent];
  for (const c of consents) {
    window.CookieInformation?.changeCategoryConsentDecision(`cookie_cat_${c}`, value);
  }
  window.CookieInformation?.submitConsent();
}
