import { v4 as uuid } from "uuid";

import type { JSONObject } from "../../types/json";
import { Analytics } from "../AnalyticsEvents";

const sendEvent = (event: string) => (data: JSONObject) => {
  return fetch(`/a/${event}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    keepalive: true,
    body: JSON.stringify({ ...data, uuid: uuid() }),
  });
};

export const initializeInsightReporter = () => {
  Analytics.event("impression", "pageView").addEventListener(sendEvent("pv"));
  Analytics.event("experiment", "initialize").addEventListener((event) =>
    sendEvent("ex")({
      experiment_id: event.experiment,
      variant_id: event.variant,
      is_staff: window.IS_STAFF ?? false,
      ...Analytics.metadata,
    }),
  );
  Analytics.event("action", "consent").addEventListener(sendEvent("cs"));
  Analytics.event("action", "navigation").addEventListener(sendEvent("ng"));
  Analytics.event("action", "search").addEventListener((event) => {
    sendEvent("sr")({ ...Analytics.metadata, ...event });
  });
  Analytics.event("action", "guidance").addEventListener((event) => {
    sendEvent("ac")({ ...Analytics.metadata, url: window.location.href, action: "guidance", payload: event });
  });
  Analytics.event("action", "selection").addEventListener(({ elementName }) => {
    sendEvent("ac")({
      ...Analytics.metadata,
      url: window.location.href,
      action: `selection`,
      payload: { elementName },
    });
  });
  Analytics.event("action", "nextPage").addEventListener(() => {
    sendEvent("ac")({ ...Analytics.metadata, url: window.location.href, action: `next-page` });
  });
  Analytics.event("action", "lightbox").addEventListener((action) => {
    sendEvent("ac")({ ...Analytics.metadata, url: window.location.href, action: `lightbox`, payload: { action } });
  });
  Analytics.event("action", "contactFaq").addEventListener(({ name }) => {
    sendEvent("ac")({ ...Analytics.metadata, url: window.location.href, action: `contact-faq`, payload: name });
  });
  Analytics.event("action", "emailEntered").addEventListener(({ email, source }) => {
    sendEvent("em")({ ...Analytics.metadata, email, source });
  });
  Analytics.event("action", "addToBasket").addEventListener((event) => {
    let navigationData = {};
    if ("navigation_source_url" in event) {
      navigationData = {
        navigation_source_url: event.navigation_source_url,
        navigation_source_type: event.navigation_source_type,
        navigation_source_name: event.navigation_source_name,
        navigation_source_id: event.navigation_source_id,
      };
    }

    sendEvent("ab")({
      ...Analytics.metadata,
      ...navigationData,
      aonumber: event.id,
      price_local: (event.price * 0.8).toFixed(2), // remove VAT
      price_dkk: (event.priceDkk * 0.8).toFixed(2), // remove VAT
      name: event.name,
      source: event.source ?? "unknown",
      quantity: event.quantity,
      manufacturer: event.brand ?? "unknown",
    });
  });
};
