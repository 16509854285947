import { Analytics } from "../AnalyticsEvents";

declare global {
  interface Window {
    profitMetrics?: {
      setEmail?: (id: string) => void;
    };
  }
}

export const initializeProfitMetrics = () => {
  Analytics.event("action", "emailEntered").addEventListener((event) => {
    window.profitMetrics?.setEmail?.(event.email);
  });
};
