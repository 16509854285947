import { z } from "zod";

export const LightboxInteractionValidator = z.union([
  z.literal("open"),
  z.literal("zoom"),
  z.literal("close"),
  z.literal("switch_image"),
]);

export type ILightboxInteractionEvent = z.infer<typeof LightboxInteractionValidator>;
