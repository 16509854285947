import { isHTMLElement, isHTMLInputElement } from "../../shared/utils/types/isHTMLElement";

function registryChangeListerne(inputField: HTMLInputElement) {
  inputField.addEventListener("change", (event) => {
    /* If there's no value in the file field, do not clone. */
    if (!inputField.value) {
      return;
    }

    const row = inputField.closest(".row");

    if (!isHTMLElement(row)) {
      return;
    }

    /* Create a clone, and append. */
    const clone = row.cloneNode(true) as HTMLElement;
    const cloneInput = clone.querySelector("input");

    clone.querySelector("label")?.remove();
    clone.querySelector(".col-md-9")?.classList.toggle("col-md-offset-4", true);
    row.after(clone);

    if (isHTMLInputElement(cloneInput)) {
      cloneInput.value = "";
      cloneInput.setAttribute("name", (cloneInput.getAttribute("name") ?? "") + 1);

      registryChangeListerne(cloneInput);
    }
  });
}

(() => {
  /* When selecting a file to upload, present the user with another input field for more files. */
  const inputField = document.querySelector("#jPriceEnquiry input[name='infoform-files']");

  if (isHTMLInputElement(inputField)) {
    registryChangeListerne(inputField);
  }
})();
