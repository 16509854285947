import { isiOSDevice } from "../shared/utils/isIOSDevice";

/**
 * Add maximum-scale=1 to viewport metatag on iOS devices.
 *
 * @remarks
 * The reason for this, is that iOS on mobile devices zooms in on focused input elements,
 * if the font-size is below 16 px. To prevent zooming, we inject maximum-scale=1,
 * as iOS ignores this statement anyway.
 *
 */

window.addEventListener("DOMContentLoaded", () => {
  if (isiOSDevice()) {
    const metaTag = document.querySelectorAll("[name='viewport']");
    if (metaTag.length > 0) {
      const content = metaTag[0].getAttribute("content");
      if (content) {
        metaTag[0].setAttribute("content", `${content}, maximum-scale=1`);
      }
    }
  }
});
