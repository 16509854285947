import { isHTMLElement, isHTMLInputElement } from "../../shared/utils/types/isHTMLElement";
import { subscribe } from "../modules/newsletter";

/* General handling of subscribe to mailing list, based on classes. */
(() => {
  document.querySelectorAll(".js-newsletter-subscribe-form,.js-newsletter-unsubscribe-form")?.forEach((form) => {
    form.addEventListener("submit", (event) => {
      event.preventDefault();

      const form = event.currentTarget;

      if (!isHTMLElement(form)) {
        return;
      }

      const getValue = (selector: string) => {
        const element = form.querySelector(selector);

        if (!isHTMLInputElement(element)) {
          return "";
        }

        return element.value ?? "";
      };

      const name = getValue("input[name=name]");
      const email = getValue("input[name=email]");
      const source = getValue("input[name=source]");
      const phone = getValue("input[name=phone]");
      const custom_property_name = getValue("input[name=custom_property_name]");
      const custom_property_value = getValue("input[name=custom_property_value]");

      const method = form.classList.contains("js-newsletter-subscribe-form") ? "subscribe" : "unsubscribe";

      subscribe(name, email, source, phone, method, custom_property_name, custom_property_value, (data) => {
        if (form.dataset.redirectOnSubscribe) {
          window.location.assign(form.dataset.redirectOnSubscribe);
        } else if (form.dataset.redirectOnUnsubscribe) {
          window.location.assign(form.dataset.redirectOnUnsubscribe);
        } else {
          alert(data.message);
        }
      });
    });
  });
})();
