import { z } from "zod";

import { undefinable } from "../../zodHelpers";
import { AlgoliaProductEventValidator, BaseProductEventValidator } from "./shared";

export const ProductEventValidator = BaseProductEventValidator.merge(
  z.object({
    /**
     * The list name where the product was viewed
     *
     * The following values describes:
     *
     * "Product List": Product listings in category pages
     * "Search Results": Search result listings
     * "Product Bundle": Bundles on product pages
     * "Product Slider": Product sliders on product pages and cms pages
     * TODO: Add more values after discussion with digital salg
     */
    list: undefinable(
      z.enum([
        "Product List",
        "Search Results",
        "Quick Search",
        "Product Bundle",
        "Generic Product Slider",
        "Popular Product Slider",
        "Alternative Product Slider",
        "Bought Together Product Slider",
        "Powerstep Product Slider",
        "Related Product Slider",
        "Seen Product Slider",
        "CMS Product List",
        "Series Slider",
      ]),
    ),
    /**
     * The position of the item inside the listing
     *
     * @example 1
     */
    position: undefinable(z.number()),

    /**
     * The query id for the search
     */
    queryId: undefinable(z.string()),

    /**
     * The query index for the search
     */
    queryIndex: undefinable(z.string()),
  }),
);

export type IProductEvent = z.infer<typeof ProductEventValidator>;
export const LIST_IDS: Record<NonNullable<IProductEvent["list"]>, string> = {
  "Product List": "product-list",
  "Search Results": "search-results",
  "Quick Search": "quick-search",
  "Product Bundle": "product-bundle",
  "Generic Product Slider": "generic-product-slider",
  "Popular Product Slider": "popular-product-slider",
  "Alternative Product Slider": "alternative-product-slider",
  "Bought Together Product Slider": "bought-together-product-slider",
  "Powerstep Product Slider": "powerstep-product-slider",
  "Related Product Slider": "related-product-slider",
  "Seen Product Slider": "seen-product-slider",
  "CMS Product List": "cms-product-list",
  "Series Slider": "series-slider",
};
